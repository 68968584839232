<template>
  <div>欢迎进入{{ projectConfig.name }}</div>
</template>

<script>
import { projectConfig } from "@/commonConfig/project.js";
export default {
  data() {
    return {
      projectConfig,
    };
  },
};
</script>

<style>
</style>